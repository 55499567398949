import React from 'react'
import Header from '../main/Header'
import Footer from '../main/Footer'

export default function Portfolio() {
    return (
        <>
            <Header />
            <section className="w3l-about-breadcrumb text-center are">
                <div className="breadcrumb-bg breadcrumb-bg-about4 py-5">

                </div>

            </section>
            <section className="portfolio my-5">
                {/* <h2 className='text-center'>Our Portfolio </h2> */}
                <div className="portfolio-grid">
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <div className="portfolio-card" >
                                <div className="portfolio-image">
                                    <a href="https://scai.arjtechnologies.com/" target='_blank'>
                                        <img src="assets/port/port-1.jpg" alt="" className='w-100' />
                                    </a>
                                </div>
                                <div className="portfolio-content">
                                    <h3>SCAI</h3>
                                    <p>School Management Software & Website</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="portfolio-card" >
                                <div className="portfolio-image">
                                    <a href="https://utthancareersinstitute.com/" target='_blank'>
                                        <img src="assets/port/port-3.jpg" alt="" className='w-100' />
                                    </a>
                                </div>
                                <div className="portfolio-content">
                                    <h3>Utthan Career Institute</h3>
                                    <p>Institute Management Software & Website</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="portfolio-card" >
                                <div className="portfolio-image">
                                    <a href="https://myskillstation.com/" target='_blank'>
                                        <img src="assets/port/port-4.jpg" alt="" className='w-100' />
                                    </a>
                                </div>
                                <div className="portfolio-content">
                                    <h3>My Skill Station</h3>
                                    <p>Developments</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="portfolio-card" >
                                <div className="portfolio-image">
                                    <a href="" target='_blank'>
                                        <img src="assets/port/port-5.jpg" alt="" className='w-100' />
                                    </a>
                                </div>
                                <div className="portfolio-content">
                                    <h3>Fixa</h3>
                                    <p>a software for manage cloth store and staching Management</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="portfolio-card" >
                                <div className="portfolio-image">
                                    <a href="https://www.seenunseendesign.com/" target='_blank'>
                                        <img src="assets/port/seen_unseen.jpg" alt="" className='w-100' />
                                    </a>
                                </div>
                                <div className="portfolio-content">
                                    <h3>SEEN UNSEEN</h3>
                                    <p>E-commerce Management Software & Website</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="portfolio-card" >
                                <div className="portfolio-image">
                                    <a href="https://www.ubgotacademy.com/" target='_blank'>
                                        <img src="assets/port/ubgot.jpg" alt="" className='w-100' />
                                    </a>
                                </div>
                                <div className="portfolio-content">
                                    <h3>UBGOT Academy</h3>
                                    <p>Institute Management Software & Website</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="portfolio-card" >
                                <div className="portfolio-image">
                                    <a href="http://online.fixa.co.in/" target='_blank'>
                                        <img src="assets/port/fixa_web.jpg" alt="" className='w-100' />
                                    </a>
                                </div>
                                <div className="portfolio-content">
                                    <h3>Fixa Mans</h3>
                                    <p>E-commerce Management Software & Website</p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
            <Footer />
        </>
    )
}
