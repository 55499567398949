import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Software_demo from "./pages/Software_demo";
import PrivacyPolicy from "./pages/privacy-policy";
import Portfolio from "./pages/Portfolio";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}> </Route>
        <Route path="/About" element={<About />}> </Route>
        <Route path="/Services" element={<Services />}> </Route>
        <Route path="/Contact" element={<Contact />}> </Route>
        <Route path="/Software_demo" element={<Software_demo />}> </Route>
        <Route path="/Portfolio" element={<Portfolio />}> </Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}> </Route>
      </Routes>
    </Router>
  );
}
