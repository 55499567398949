import React from 'react'
import Header from '../main/Header'
import Footer from '../main/Footer'

export default function Software_demo() {
    return (
        <>
            <Header />
            <div className="soft_demo">
                <h1>Launching Soon</h1>
            </div>
            <Footer />
        </>
    )
}
