import React from 'react'
import Header from '../main/Header'
import Footer from '../main/Footer'

export default function Contact() {
    return (
        <>
            <Header />
            {/* about banner */}
            <section className="w3l-about-breadcrumb text-center are">
                <div className="breadcrumb-bg breadcrumb-bg-about2 py-5">

                </div>

            </section>
            {/* //about banner */}
            {/* contact */}
            <section className="w3l-contacts-12">
                <div className="contact-top pt-5">
                    <div className="container py-lg-4">
                        <div className="d-grid cont-main-top mt-lg-5 mt-4">
                            {/* contact form */}
                            <div className="contacts12-main">
                                <form
                                    action=""
                                    method=""
                                    className="main-input"
                                >
                                    <div className="top-inputs d-grid">
                                        <input
                                            type="text"
                                            placeholder="Name"
                                            name="w3lName"
                                            id="w3lName"
                                            required=""
                                        />
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            id="w3lSender"
                                            required=""
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Phone Number"
                                        name="w3lName"
                                        id="w3lName"
                                        required=""
                                    />
                                    <textarea
                                        placeholder="Message"
                                        name="w3lMessage"
                                        id="w3lMessage"
                                        required=""
                                        defaultValue={""}
                                    />
                                    <div className="text-end">
                                        <button type="submit" className="btn btn-primary btn-style">
                                            Submit Now
                                        </button>
                                    </div>
                                </form>
                            </div>
                            {/* //contact form */}
                            {/* contact address */}
                            <div className="contact">
                                <div className="cont-subs">
                                    <div className="cont-add">
                                        <h4>Company Location:</h4>
                                        <p className="contact-text-sub">
                                            {" "}
                                            3rd Floor, Above NBC Boring Chauraha, Jhotwara, Jaipur
                                        </p>
                                    </div>
                                    <div className="cont-add">
                                        <h4>Email Address:</h4>
                                        <a href="mailto:contact@arjtechnologies.com">
                                            <p className="contact-text-sub">contact@arjtechnologies.com</p>
                                        </a>
                                    </div>
                                    <div className="cont-add">
                                        <h4>Phone Number:</h4>
                                        <a href="tel:+91 7733095696">
                                            <p className="contact-text-sub">+91 7733095696</p>
                                        </a>
                                    </div>
                                    <div className="social-icons-con">
                                        <h4 className="mb-3">Connect with us:</h4>
                                        <a href="https://arjtechnologies.com/" target='_blank'>
                                            <span className="fab fa-google" aria-hidden="true" />
                                        </a>
                                        <a href="https://www.linkedin.com/company/arj-technologies/" target='_blank'>
                                            <span className="fab fa-linkedin-in" aria-hidden="true" />
                                        </a>
                                        <a href="https://www.instagram.com/arj_technologies/" target='_blank'>
                                            <span className="fab fa-instagram" aria-hidden="true" />
                                        </a>
                                        <a href="/" target='_blank'>
                                            <span className="fab fa-facebook-square" aria-hidden="true" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/* //contact address */}
                        </div>
                    </div>
                    {/* map */}
                    <div className="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1774.8848873158634!2d75.722915!3d27.163533!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396dab218f50992f%3A0x180e014178bd765!2sARJ%20INSTITUTE%20OF%20TECHNOLOGY!5e0!3m2!1sen!2sin!4v1727024240933!5m2!1sen!2sin"
                            width={800}
                            height={600}
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />

                    </div>
                    {/* //map */}
                </div>
            </section>
            {/* //contact */}
            <Footer />
        </>

    )
}
